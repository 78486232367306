import React                from "react";
import PropTypes            from "prop-types";
import Styled               from "styled-components";
import Store                from "Utils/Store";

// Core
import Settings             from "Core/Settings";

// Components
import Branding             from "Components/Core/Branding";
import Icon                 from "Components/Utils/Icon";



// Styles
const Container = Styled.footer.attrs(({ forChat }) => ({ forChat }))`
    position: relative;
    width: 100%;
    max-width: var(--main-width);
    margin: 0 auto;

    ${(props) => props.forChat && `
        margin-bottom: 8px;
        @media (max-width: 600px) {
            display: none;
        }
    `}
`;

const ConfigIcon = Styled(Icon)`
    position: absolute;
    bottom: 4px;
    right: 8px;
    font-size: 18px;
    cursor: pointer;
`;



/**
 * The Footer
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function Footer(props) {
    const { forChat } = props;
    const { languages } = Store.useState();

    const showSettings = Store.useSettings();


    // Variables
    const hasLanguages = languages.length > 1;


    // Do the Render
    return <Container forChat={forChat}>
        {hasLanguages && <>
            <ConfigIcon
                icon="settings"
                onClick={() => showSettings(true)}
            />
            <Settings />
        </>}
        <Branding />
    </Container>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
Footer.propTypes = {
    forChat : PropTypes.bool,
};

export default Footer;
