import React                from "react";
import PropTypes            from "prop-types";
import Styled               from "styled-components";



// Styles
const Container = Styled.textarea`
    flex-grow: 2;
    appearance: none;
    margin: 0;
    padding: 0;
    border: none;
    resize: none;
    color: #687992;
    font-size: 14px;
    line-height: 18px;
    min-height: 18px;

    &:focus {
        outline: none;
    }
`;



/**
 * The Textarea
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function Textarea(props) {
    const { passedRef, placeholder, value, onChange, onSubmit } = props;

    const textareaRef = React.useRef(null);
    const inputRef    = passedRef || textareaRef;

    const [ rows, setRows ] = React.useState(1);

    // Handles the Key Down
    const handleKeyDown = (e) => {
        if (e.keyCode === 13 && !e.shiftKey) {
            onSubmit();
            e.preventDefault();
        }
    };

    // Handles the Textarea Input
    const handleInput = (e) => {
        const newRows = handleAutoGrow();
        onChange(e.target.value, newRows);
    };

    // Handles the Textarea AutoGrow
    const handleAutoGrow = () => {
        const textareaLineHeight = 18;
        const node     = inputRef.current;
        const prevRows = node.rows;
        node.rows = 1;

        const currentRows = ~~(node.scrollHeight / textareaLineHeight);
        if (currentRows === prevRows) {
            node.rows = currentRows;
        }
        setRows(currentRows);
        return currentRows;
    };

    // Resize the Textarea the first time
    React.useEffect(() => {
        if (value) {
            const newRows = handleAutoGrow();
            onChange(value, newRows);
        }
    }, [ value ]);


    // Do the Render
    return <Container
        ref={inputRef}
        placeholder={placeholder}
        onInput={handleInput}
        onKeyDown={handleKeyDown}
        value={value}
        rows={rows}
    />;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
Textarea.propTypes = {
    passedRef   : PropTypes.any,
    placeholder : PropTypes.string.isRequired,
    value       : PropTypes.string.isRequired,
    onChange    : PropTypes.func.isRequired,
    onSubmit    : PropTypes.func.isRequired,
};

export default Textarea;
