import React                from "react";
import PropTypes            from "prop-types";
import Styled               from "styled-components";
import Store                from "Utils/Store";
import Utils                from "Utils/Utils";

// Components
import Branding             from "Components/Core/Branding";
import Button               from "Components/Utils/Button";
import Textarea             from "Components/Utils/Textarea";
import Icon                 from "Components/Utils/Icon";

// Emoji
import EmojiPicker, {
    EmojiStyle,
} from "emoji-picker-react";



// Styles
const Container = Styled.div`
    position: sticky;
    bottom: 0;
    color: #91989e;
    background-color: white;
    z-index: 2;
`;

const ChatBranding = Styled(Branding)`
    @media (min-width: 600px) {
        display: none;
    }
`;

const Writing = Styled.div`
    display: block;
    padding: 8px 12px;
    text-decoration: none;
    font-size: 12px;
    color: #91989e;
`;

const Inside = Styled.section`
    padding: 6px 6px 6px 12px;
    border-top: 1px solid #d9e1ec;
    cursor: text;
`;

const Content = Styled.div.attrs(({ multiRows }) => ({ multiRows }))`
    display: flex;
    align-items: ${(props) => props.multiRows ? "flex-end" : "center"};
    gap: 4px;
`;
const PreContent = Styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
    padding: 4px 8px;
    color: black;
    background-color: rgb(235, 236, 240);
    border-radius: var(--border-radius-small);
`;
const PreContentText = Styled.div`
    width: calc(100% - 16px);
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
`;
const PreContentClose = Styled(Icon)`
    font-size: 14px;
    cursor: pointer;
`;

const FileError = Styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: rgb(188, 28, 72);
    border-radius: var(--border-radius-small);
    color: white;
    padding: 4px 8px;
    margin-bottom: 4px;
`;

const FileInput = Styled.input`
    display: none;
`;

const Emojis = Styled.div.attrs(({ bottom }) => ({ bottom }))`
    position: fixed;
    left: 0;
    right: 0;
    bottom: ${(props) => `${props.bottom}px`};
    max-width: 100%;
    border-top: 1px solid #d9e1ec;
    border-bottom: 1px solid #d9e1ec;
    z-index: 5;

    aside {
        --epr-picker-border-radius: 0px;
        --epr-picker-border-color: white;

        box-sizing: border-box;
        width: 100% !important;
        max-width: 100%;
        max-height: calc(100dvh - 70px - ${(props) => `${props.bottom}px`});
    }

    @media (width: 400px) {
        left: 15px;
        right: 15px;
        bottom: ${(props) => `${props.bottom + 15}px`};
        max-width: 370px;

        aside {
            width: 370px !important;
            max-height: calc(min(100dvh, var(--iframe-height)) - 30px - 70px - ${(props) => `${props.bottom}px`});
        }
    }
`;



/**
 * The Send
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function Send(props) {
    const { replyToID, replyMessage, onSubmit, onReplyRemove } = props;

    const { name, options, conversation } = Store.useState();

    const getText      = Store.useText();
    const sendMessage  = Store.useMessage();
    const sendLocation = Store.useLocation();


    // The References
    const containerRef = React.useRef(null);
    const fileRef      = React.useRef(null);
    const inputRef     = React.useRef(null);

    // The Current State
    const [ message,         setMessage         ] = React.useState("");
    const [ file,            setFile            ] = React.useState(null);
    const [ fileName,        setFileName        ] = React.useState("");
    const [ sizeError,       setSizeError       ] = React.useState(false);
    const [ rows,            setRows            ] = React.useState(1);
    const [ emojiOpen,       setEmojiOpen       ] = React.useState(false);
    const [ bottom,          setBottom          ] = React.useState(0);
    const [ sendingLocation, setSendingLocation ] = React.useState(false);


    // Focus the Input
    React.useEffect(() => {
        if (replyToID) {
            inputRef.current.focus();
        }
    }, [ replyToID ]);


    // Handles the Click
    const handleClick = () => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    };

    // Handles the Message
    const handleMessage = (message, rows) => {
        setMessage(message);
        setRows(rows);
    };

    // Handles the Emoji Open
    const handleEmojiOpen = () => {
        setEmojiOpen(!emojiOpen);
        const bounds = containerRef.current.getBoundingClientRect();
        setBottom(bounds.height - 1);
    };

    // Handles the Emoji Insert
    const handleEmojiInsert = (elem) => {
        setMessage(message + elem.emoji);
        setEmojiOpen(false);
    };

    // Handles the File Open
    const handleFileOpen = () => {
        fileRef.current.click();
    };

    // Handles the File Change
    const handleFileChange = (e) => {
        if (e.target.files && e.target.files[0]) {
            const file = e.target.files[0];
            const size = file.size / (1024 * 1024);
            if (size > 3) {
                setSizeError(true);
            } else {
                setFile(file);
                setFileName(file.name);
                setSizeError(false);
            }
        }
    };

    // Handles the File Remove
    const handleFileRemove = () => {
        setFile(null);
        setFileName("");
        setSizeError(false);
        fileRef.current.value = "";
    };

    // Handles the Location
    const handleLocation = () => {
        setSendingLocation(true);
        navigator.geolocation.getCurrentPosition((position) => {
            const { latitude, longitude } = position.coords;
            sendLocation(latitude, longitude, getText("myLocation"));
            setSendingLocation(false);
            inputRef.current.focus();
        });
    };

    // handles the Submit
    const handleSubmit = () => {
        if (!message && !file) {
            return;
        }

        sendMessage(name, message, file, replyToID);
        setMessage("");
        onSubmit();
        onReplyRemove();
        handleFileRemove();
        inputRef.current.focus();
    };


    // Variables
    const writingText        = getText("writingText");
    const showWriting        = Boolean(conversation.isWriting && writingText);
    const showEmojiButton    = Utils.isActive(options.showEmojiButton);
    const showFileButton     = Utils.isActive(options.showFileButton);
    const showLocationButton = Utils.isActive(options.showLocationButton) && Boolean(navigator.geolocation);


    // Do the Render
    return <>
        <Container>
            {showWriting ?
                <Writing>{writingText}</Writing>
                : <ChatBranding />}

            <Inside ref={containerRef} onClick={handleClick}>
                {!!replyToID && <PreContent>
                    <PreContentText>
                        <b>{getText("replyingTo")}</b><br />
                        {replyMessage}
                    </PreContentText>
                    <PreContentClose
                        icon="close"
                        onClick={() => onReplyRemove()}
                    />
                </PreContent>}

                {sizeError && <FileError>
                    <PreContentText>{getText("sizeError")}</PreContentText>
                    <PreContentClose
                        icon="close"
                        onClick={() => setSizeError(false)}
                    />
                </FileError>}

                {sendingLocation && <PreContent>
                    <PreContentText>{getText("sendingLocation")}</PreContentText>
                </PreContent>}

                {!!fileName && <PreContent>
                    <PreContentText>{fileName}</PreContentText>
                    <PreContentClose
                        icon="close"
                        onClick={handleFileRemove}
                    />
                </PreContent>}

                <Content multiRows={rows > 1}>
                    <Textarea
                        passedRef={inputRef}
                        placeholder={getText("sendText")}
                        onChange={handleMessage}
                        onSubmit={handleSubmit}
                        value={message}
                    />
                    {showEmojiButton && <Button
                        icon="emoji"
                        onClick={handleEmojiOpen}
                    />}
                    {showFileButton && <Button
                        icon="attachment"
                        onClick={handleFileOpen}
                    />}
                    {showLocationButton && <Button
                        icon="address"
                        onClick={handleLocation}
                    />}
                    <Button
                        icon="send"
                        onClick={handleSubmit}
                        isDisabled={sendingLocation}
                        isPrimary
                    />
                </Content>
            </Inside>
        </Container>

        <FileInput
            ref={fileRef}
            type="file"
            onChange={handleFileChange}
        />

        {emojiOpen && <Emojis bottom={bottom}>
            <EmojiPicker
                onEmojiClick={handleEmojiInsert}
                emojiStyle={EmojiStyle.NATIVE}
                previewConfig={{ showPreview : false }}
                skinTonesDisabled={true}
            />
        </Emojis>}
    </>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
Send.propTypes = {
    replyToID     : PropTypes.number.isRequired,
    replyMessage  : PropTypes.string.isRequired,
    onSubmit      : PropTypes.func.isRequired,
    onReplyRemove : PropTypes.func.isRequired,
};

export default Send;
