import Utils                from "./Utils";

// Module variables
let controller = null;



/**
 * Fetch wrapper
 * @throws {Object|String} The errors
 * @param {(String|URL)} url
 * @param {Object=}      options
 * @returns {Promise}
 */
async function ajax(url, options = {}) {
    let response   = null;
    let result     = null;
    const defError = { "form" : "GENERAL_ERROR" };

    // To be able to Abort
    if (window.AbortController) {
        controller     = new window.AbortController();
        options.signal = controller.signal;
    }

    // Do the Request
    try {
        response = await fetch(url, options);
    } catch (error) {
        throw defError;
    }

    // Bad Response
    if (!response.ok) {
        throw defError;
    }

    // Get the JSON Result
    try {
        result = await response.json();
    } catch (error) {
        throw defError;
    }
    if (!result) {
        result = {};
    }

    // There was an error
    if (result.errors && !Utils.isEmpty(result.errors)) {
        throw result.errors;
    }
    if (result.error) {
        throw result.error;
    }

    // Show the Result
    if (!result.data) {
        result.data = {};
    }
    if (result.success) {
        result.data.success = result.success;
    } else if (result.warning) {
        result.data.warning = result.warning;
    }

    // Return just the data
    return result.data;
}

/**
 * Aborts a Fetch
 * @returns {Void}
 */
function abort() {
    if (controller) {
        controller.abort();
    }
}



/**
 * Creates a new Url
 * @param {String} route
 * @returns {URL}
 */
function createUrl(route) {
    const baseUrl = process.env.REACT_APP_API;
    return new URL(`${baseUrl}${route}`);
}

/**
 * Does a Get
 * @param {String}  route
 * @param {Object=} params
 * @returns {Promise}
 */
async function get(route, params = {}) {
    const url = createUrl(route);

    url.searchParams.append("token", process.env.REACT_APP_TOKEN);
    for (const [ key, value ] of Object.entries(params)) {
        url.searchParams.append(key, value);
    }

    let result;
    try {
        result = await ajax(url, {});
    } catch(e) {
        result = { error : true };
    }
    return result;
}

/**
 * Does a Post
 * @param {String}  route
 * @param {Object=} params
 * @returns {Object}
 */
function post(route, params = {}) {
    const url  = createUrl(route);
    const body = new FormData();

    body.append("token", process.env.REACT_APP_TOKEN);
    for (const [ key, value ] of Object.entries(params)) {
        body.append(key, value);
    }
    return ajax(url, { method : "post", body });
}



// The public API
export default {
    abort,
    get,
    post,


    // The actions
    getInitial     : (data) => post("/widget/chat/getInitial",     data),
    getNewMessages : (data) => post("/widget/chat/getNewMessages", data),
    getOldMessages : (data) => post("/widget/chat/getOldMessages", data),
    markAsRead     : (data) => post("/widget/chat/markAsRead",     data),
    sendMessage    : (data) => post("/widget/chat/sendMessage",    data),
    sendReaction   : (data) => post("/widget/chat/sendReaction",   data),
};
