import React                from "react";
import Styled               from "styled-components";
import Store                from "Utils/Store";

// Components
import Header               from "Components/Core/Header";
import Footer               from "Components/Core/Footer";
import Initiate             from "Components/Start/Initiate";
import Return               from "Components/Start/Return";
import Platforms            from "Components/Start/Platforms";
import Platform             from "Components/Start/Platform";



// Styles
const Container = Styled.main`
    flex-grow: 2;
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    max-width: var(--main-width);
    margin: -60px auto 0;
`;

const Content = Styled.section`
    display: flex;
    flex-direction: column;
    gap: 16px;
    position: relative;
    min-height: 60px;
    padding: 24px;
    background-color: white;
    border-radius: var(--border-radius);
    box-shadow: var(--box-shadow);
    z-index: 2;

    @media (max-width: 600px) {
        margin: 0 24px;
    }
`;



/**
 * The Start
 * @returns {React.ReactElement}
 */
function Start() {
    const { options } = Store.useState();

    const getText = Store.useText();


    // Do the Render
    return <>
        <Header forStart>
            {!!options.logoFile && <img src={options.logoFileUrl} />}
            {getText("headerText")}
        </Header>

        <Container>
            <Content>
                <Initiate />
                <Return />
                <Platforms />
                <Platform />
            </Content>
            <Footer />
        </Container>
    </>;
}

export default Start;
