import React                                from "react";
import { createRoot }                       from "react-dom/client";
import * as ServiceWorker                   from "serviceWorker";
import { pdfjs }                            from "react-pdf";

// Utils
import Store                                from "Utils/Store";

// Components
import Main                                 from "Core/Main";

// PDF
import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";

// Styles
import "Styles/Main.css";
import "Styles/Properties.css";
import "Styles/Icons.css";



// React PDF
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;



// Calculate the Max height
let maxHeight = 0;
document.documentElement.style.setProperty("--iframe-height", "1000px");
window.addEventListener("resize", () => {
    const fullHeight = window.innerHeight;
    if (fullHeight > maxHeight) {
        maxHeight = fullHeight;
        document.documentElement.style.setProperty("--iframe-height", `${fullHeight}px`);
    }
});



// Render the App
const root = createRoot(document.getElementById("root"));
root.render(
    <Store.Provider>
        <Main />
    </Store.Provider>
);

ServiceWorker.register();
