import React                from "react";
import PropTypes            from "prop-types";
import Styled               from "styled-components";
import Store                from "Utils/Store";



// Styles
const Container = Styled.footer`
    display: block;
    padding: 8px 5px;
    text-align: center;
    font-size: 12px;
`;

const Link = Styled.a`
    text-decoration: none;
    color: #91989e;
`;



/**
 * The Branding
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function Branding(props) {
    const { className } = props;

    const { url } = Store.useState();
    const getText = Store.useText();


    // Do the Render
    return <Container className={className}>
        <Link href={url} target="_blank">
            {getText("branding")} <b>Conversana</b>
        </Link>
    </Container>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
Branding.propTypes = {
    className : PropTypes.string,
};

export default Branding;
