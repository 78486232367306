import React                from "react";
import PropTypes            from "prop-types";
import Styled               from "styled-components";

// Components
import PDFViewer            from "Components/Utils/PDFViewer";



// Styles
const ImageLink = Styled.a`
    display: block;
    width: 300px;
    max-width: 100%;
`;

const Image = Styled.img`
    display: block;
    max-width: 100%;
    border-radius: var(--border-radius-small);
    overflow: hidden;
`;

const Video = Styled.video`
    display: block;
    width: 100%;
    max-height: 400px;
    max-width: 400px;
    border-radius: var(--border-radius-small);
    overflow: hidden;
`;

const Audio = Styled.audio`
    display: block;
`;

const PDF = Styled.div`
    width: 100%;
    max-width: 300px;
    max-height: 100px;
    border-radius: var(--border-radius-small);
    overflow: hidden;
    cursor: pointer;
`;

const FileLink = Styled.a`
    display: block;
    color: var(--bubble-color);
    text-decoration: underline;
    transition: 0.2s opacity;

    &:hover {
        opacity: 0.5;
    }
`;



/**
 * The Media
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function Media(props) {
    const { media } = props;


    // Do the Render
    const hasLink = media.hasFile || media.hasUrl;

    return <>
        {media.hasImage && <ImageLink
            href={media.fileUrl}
            target="_blank"
        >
            <Image
                className="bubble-media"
                src={media.fileUrl}
            />
        </ImageLink>}

        {media.hasVideo && <Video
            className="bubble-media"
            src={media.fileUrl}
            controls
        />}

        {media.hasAudio && <Audio
            className="bubble-media"
            src={media.fileUrl}
            controls
        />}

        {media.hasPDF && <PDF
            className="bubble-media"
            onClick={() => window.open(media.fileUrl)}
        >
            <PDFViewer
                source={media.fileUrl}
                maxWidth={300}
            />
        </PDF>}

        {hasLink && <FileLink
            href={media.fileUrl}
            target="_blank"
        >{media.fileText}</FileLink>}
    </>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
Media.propTypes = {
    media : PropTypes.object,
};

/**
 * The Default Properties
 * @type {Object} defaultProps
 */
Media.defaultProps = {
    media : {},
};

export default Media;
