import React                from "react";
import Store                from "Utils/Store";

// Core
import App                  from "Core/App";



/**
 * The Main
 * @returns {React.ReactElement}
 */
function Main() {
    const { error, loading } = Store.useState();
    const initialize = Store.useInitial();


    // Loads the Chat
    React.useEffect(() => {
        initialize();
    }, []);


    // Do the Render
    if (error || loading) {
        return <React.Fragment />;
    }
    return <App />;
}

export default Main;
