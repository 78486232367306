import React                from "react";
import Styled               from "styled-components";
import Store                from "Utils/Store";
import Utils                from "Utils/Utils";

// Components
import Icon                 from "Components/Utils/Icon";
import Title                from "Components/Utils/Title";



// Styles
const Content = Styled.a.attrs(({ color }) => ({ color }))`
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 6px;
    border: var(--border);
    border-radius: var(--border-radius-small);
    color: #24374e;
    text-decoration: none;
    transition: all 0.2s;
    cursor: pointer;

    &:not(:last-child) {
        margin-bottom: 8px;
    }
    &:hover {
        background-color: ${(props) => props.color};
        border-color: ${(props) => props.color};
        color: white;
    }
`;

const ChannelIcon = Styled(Icon).attrs(({ color, size }) => ({ color, size }))`
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${(props) => `${props.size}px`};
    height: ${(props) => `${props.size}px`};
    font-size: ${(props) => `${props.size / 2}px`};
    background-color: ${(props) => props.color};
    border-radius: var(--border-radius-small);
    color: white;
`;



/**
 * The Platforms
 * @returns {React.ReactElement}
 */
function Platforms() {
    const { options, platforms, platform } = Store.useState();

    const getText      = Store.useText();
    const gotoPlatform = Store.usePlatform();


    // Variables
    const hasPlatforms  = Utils.isActive(options.showPlatforms);
    const showPlatform  = hasPlatforms && platform !== null;
    const showPlatforms = hasPlatforms && !showPlatform && platforms.length > 0;


    // Handles the Platform click
    const handlePlatform = (e, item) => {
        if (window.innerWidth !== 400) {
            return;
        }
        if (item.showQR) {
            e.preventDefault();
            gotoPlatform(item);
        }
    };


    // Do the Render
    if (!showPlatforms) {
        return <React.Fragment />;
    }
    return <>
        <Title>{getText("platformsText")}</Title>
        <div>
            {platforms.map((item) => <Content
                key={item.id}
                color={item.color}
                href={item.url}
                target="_blank"
                onClick={(e) => handlePlatform(e, item)}
            >
                <ChannelIcon
                    icon={item.icon}
                    color={item.color}
                    size={24}
                />
                <span>{item.name}</span>
            </Content>)}
        </div>
    </>;
}

export default Platforms;
