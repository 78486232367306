import React                from "react";
import Styled               from "styled-components";
import QRCode               from "react-qr-code";
import Store                from "Utils/Store";

// Components
import Icon                 from "Components/Utils/Icon";
import Title                from "Components/Utils/Title";



// Styles
const WebLink = Styled.a`
    padding: 12px 16px;
    color: #24374e;
    border: var(--border);
    border-radius: var(--border-radius-small);
    transition: all 0.2s;
    text-align: center;
    text-decoration: none;
    cursor: pointer;

    &:empty {
        display: none;
    }
    &:hover {
        border-color: black;
    }
`;

const Code = Styled(QRCode)`
    height: auto;
    width: calc(100% - 32px * 2);
    padding: 16px 32px;
    border: var(--border);
    border-radius: var(--border-radius-small);
`;



/**
 * The Platform
 * @returns {React.ReactElement}
 */
function Platform() {
    const { platform } = Store.useState();
    const getText      = Store.useText();
    const gotoPlatform = Store.usePlatform();


    // Variables
    const showPlatform = platform !== null;


    // Do the Render
    if (!showPlatform) {
        return <React.Fragment />;
    }
    return <>
        <Title>
            <Icon
                icon="back"
                onClick={() => gotoPlatform(null)}
            />
            {`${getText("connectText")} `}
            {platform.name}
        </Title>
        <WebLink href={platform.url} target="_blank">
            {getText("webLinkText")}
        </WebLink>
        <Title>{getText("qrCodeText")}</Title>
        <Code
            size={256}
            value={platform.url}
        />
    </>;
}

export default Platform;
