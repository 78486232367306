import React                from "react";
import PropTypes            from "prop-types";
import Styled               from "styled-components";

// Components
import Media                from "Components/Chat/Media";
import Html                 from "Components/Utils/Html";



// Styles
const Container = Styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    border-left: 4px solid;
    padding: 8px;
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: var(--border-radius-small);
    opacity: 0.6;

    .bubble-media {
        max-width: 100px;
        max-height: 64px;
    }
`;

const Text = Styled(Html)`
    max-height: 84px;
    overflow: hidden;
    text-overflow: ellipsis;
`;



/**
 * The Reply
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function Reply(props) {
    const { item, hasText, hasIcon } = props;

    const files = item.replyFiles || [];


    // Do the Render
    if (!item.replyToID) {
        return <React.Fragment />;
    }
    return <Container>
        {files.map((file) => <Media
            key={file.fileID}
            media={file}
            hasText={hasText}
            hasIcon={hasIcon}
        />)}
        <Text
            content={item.replyMessage}
            addBreaks
            formatText
        />
    </Container>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
Reply.propTypes = {
    item    : PropTypes.object,
    hasText : PropTypes.bool,
    hasIcon : PropTypes.bool,
};

/**
 * The Default Properties
 * @type {Object} defaultProps
 */
Reply.defaultProps = {
    item : {},
};

export default Reply;
