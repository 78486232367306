import React                from "react";
import PropTypes            from "prop-types";

// Components
import Icon                 from "./Icon";

// Styled
import Styled, {
    keyframes,
} from "styled-components";



// Animations
const animation = keyframes`
    0% {
        transform: scale(1, 1);
    }
    100% {
        transform: scale(0.8, 0.8);
    }
`;

// Styles
const Container = Styled.button.attrs(({ isPrimary, isDisabled }) => ({ isPrimary, isDisabled }))`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    width: 32px;
    margin: 0;
    border: none;
    border-radius: var(--border-radius-small);
    font-size: 16px;
    flex-shrink: 0;
    cursor: ${(props) => props.isDisabled ? "not-allowed" : "pointer"};

    &:focus {
        animation: ${animation} 0.5s ease-in-out backwards;
    }
    &:active {
        animation: none;
    }

    ${(props) => props.isPrimary ? `
        background-color: var(--widget-button-bg);
        color: var(--widget-button-icon);
    ` : `
        background-color: #f1f3f8;
        color: rgb(34, 34, 34);
    `}
`;



/**
 * The Button
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function Button(props) {
    const { icon, onClick, isPrimary, isDisabled } = props;


    // Do the Render
    return <Container
        isPrimary={isPrimary}
        isDisabled={isDisabled}
        onClick={!isDisabled ? onClick : null}
    >
        <Icon icon={icon} />
    </Container>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
Button.propTypes = {
    icon       : PropTypes.string.isRequired,
    onClick    : PropTypes.func,
    isPrimary  : PropTypes.bool,
    isDisabled : PropTypes.bool,
};

export default Button;
