import React                from "react";
import PropTypes            from "prop-types";
import Styled               from "styled-components";



// Styles
const Container = Styled.div`
    position: fixed;
    inset: 0px;
    background-color: rgba(9, 30, 66, .5);
    z-index: 10;

    @media (width: 400px) {
        inset: 15px;
        border-radius: var(--border-radius);
    }
`;



/**
 * The Backdrop
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function Backdrop(props) {
    const { onClick, children } = props;

    // Do the Render
    return <Container onClick={onClick}>
        {children}
    </Container>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
Backdrop.propTypes = {
    onClick  : PropTypes.func,
    children : PropTypes.any,
};

export default Backdrop;
