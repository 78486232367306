import React                from "react";
import PropTypes            from "prop-types";
import Styled               from "styled-components";
import Store                from "Utils/Store";
import Utils                from "Utils/Utils";

// Components
import Icon                 from "Components/Utils/Icon";



// Styles
const Container = Styled.header`
    flex-shrink: 0;
    box-sizing: border-box;
    position: sticky;
    top: 0;
    color: var(--widget-header-text);
    background-color: var(--widget-header-bg);
    z-index: 2;

    img {
        max-height: 50px;
    }

    @media (width: 400px) {
        border-top-left-radius: 16px;
        border-top-right-radius: 16px;
    }
`;

const Close = Styled(Icon)`
    position: absolute;
    top: 8px;
    right: 8px;
    color: var(--widget-header-text);
    font-size: 20px;
    z-index: 3;
    cursor: pointer;
`;

const Content = Styled.div.attrs(({ leftAlign, hasIcon, forStart }) => ({ leftAlign, hasIcon, forStart }))`
    position: relative;
    box-sizing: border-box;
    display: flex;
    justify-content: ${(props) => props.leftAlign ? "flex-start" : "center"};
    align-items: center;
    width: 100%;
    max-width: var(--main-width);
    min-height: 70px;
    margin: 0 auto;
    text-align: center;
    font-size: 24px;
    font-weight: 400;

    ${(props) => props.forStart ? `
        padding: 24px 30px 84px 30px;
    ` : `
        padding: 24px 30px 84px 30px;
        @media (max-width: 600px) {
            padding: ${props.hasIcon ? "24px 40px" : "24px 30px"};
        }
    `}
`;



/**
 * The Header
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function Header(props) {
    const { hasIcon, forStart, children } = props;

    const { options } = Store.useState();


    // Variables
    const leftAlign = Utils.isActive(options.headerLeftAlign);
    const inIframe  = window.self !== window.top;


    // Handles the Close
    const handleClose = () => {
        window.parent.postMessage("close", "*");
    };


    // Do the Render
    return <Container>
        {inIframe && <Close icon="close" onClick={handleClose} />}
        <Content
            leftAlign={leftAlign}
            hasIcon={hasIcon}
            forStart={forStart}
        >
            {children}
        </Content>
    </Container>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
Header.propTypes = {
    hasIcon  : PropTypes.bool,
    forStart : PropTypes.bool,
    children : PropTypes.any,
};

export default Header;
