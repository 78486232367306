import React                from "react";
import Styled               from "styled-components";
import Store                from "Utils/Store";
import Utils                from "Utils/Utils";

// Core
import Start                from "Core/Start";
import Chat                 from "Core/Chat";



// Styles
const Container = Styled.main`
    box-sizing: border-box;
    position: relative;
    background-color: white;
    font-family: var(--widget-font);

    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 100dvh;
    overflow: auto;

    @media (width: 400px) {
        width: 371px;
        margin: 15px;
        height: auto;
        max-height: calc(var(--iframe-height) - 30px - 1px);
        border-radius: var(--border-radius);
        box-shadow: 0 0 20px 0 rgb(104 121 146 / 20%);
    }
`;



/**
 * The App
 * @returns {React.ReactElement}
 */
function App() {
    const {
        name, showStartPage, options,
        contactID, conversation, unreadCount, lastUpdate,
    } = Store.useState();

    const getNewMessages = Store.useNewMessages();
    const sendMessage    = Store.useMessage();


    // The References
    const containerRef = React.useRef(null);

    // The Current State
    const [ timer,    setTimer    ] = React.useState(null);
    const [ observer, setObserver ] = React.useState(null);
    const [ isOpen,   setIsOpen   ] = React.useState(false);

    // Variables
    const showStart     = Utils.isActive(options.showStart) && showStartPage;
    const autoStartChat = !showStart && Utils.isActive(options.autoStartChat);


    // Update the iframe height
    React.useEffect(() => {
        if (containerRef.current) {
            const resizeObserver = new ResizeObserver(() => {
                const bounds = containerRef.current.getBoundingClientRect();
                window.parent.postMessage(`height-${bounds.height}`, "*");
                setIsOpen(!!bounds.height);
            });
            resizeObserver.observe(containerRef.current);
            setObserver(resizeObserver);
        }
        return () => {
            if (observer) {
                observer.disconnect();
            }
        };
    }, [ containerRef.current ]);

    // Handle the Auto-update
    React.useEffect(() => {
        if (timer) {
            window.clearTimeout(timer);
        }
        if (conversation.id) {
            setTimer(window.setTimeout(() => {
                getNewMessages();
            }, 5 * 1000));
        }
        return () => {
            if (timer) {
                window.clearTimeout(timer);
                setTimer(null);
            }
        };
    }, [ conversation.id, lastUpdate ]);

    // Send the unread count
    React.useEffect(() => {
        if ((isOpen && (!unreadCount || showStartPage)) || !isOpen) {
            window.parent.postMessage(`unread-${unreadCount}`, "*");
        }
    }, [ isOpen, unreadCount ]);

    // Auto start the Chat
    React.useEffect(() => {
        if (isOpen && !contactID && autoStartChat) {
            sendMessage(name, "👋");
        }
    }, [ isOpen ]);



    // The custom colors
    const style = {
        "--widget-font"          : `${options.fontFamily || "Inter"}, "Helvetica Neue", Helvetica, Arial, sans-serif`,
        "--widget-header-bg"     : options.headerBgColor,
        "--widget-header-text"   : options.headerTextColor,
        "--widget-button-bg"     : options.buttonBgColor,
        "--widget-button-icon"   : options.buttonIconColor,
        "--widget-icon-bg"       : options.iconBgColor,
        "--widget-icon-icon"     : options.iconIconColor,
        "--widget-bubble-bg"     : options.bubbleBgColor,
        "--widget-bubble-border" : options.bubbleBorderColor,
        "--widget-bubble-text"   : options.bubbleTextColor,

        "--bubble-mine-bg"       : options.contactMessageBgColor,
        "--bubble-mine-color"    : options.contactMessageTextColor,
        "--bubble-your-bg"       : options.userMessageBgColor,
        "--bubble-your-color"    : options.userMessageTextColor,
        "--bubble-button-color"  : options.bubbleButtonColor,
        "--bubble-button-hover"  : options.bubbleButtonHover,
    };


    // Do the Render
    // @ts-ignore
    return <Container ref={containerRef} style={style}>
        {showStart ? <Start /> : <Chat isOpen={isOpen} />}
    </Container>;
}

export default App;
