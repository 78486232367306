import React                from "react";
import PropTypes            from "prop-types";
import Styled               from "styled-components";



// Styles
const Container = Styled.h3`
    display: flex;
    align-items: center;
    gap: 8px;
    margin: 0;
    color: #24374e;
    font-size: 18px;
    font-weight: 600;

    &:empty {
        display: none;
    }
    .icon {
        cursor: pointer;
    }
`;



/**
 * The Title
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function Title(props) {
    const { children } = props;


    // Do the Render
    return <Container>
        {children}
    </Container>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
Title.propTypes = {
    children : PropTypes.any.isRequired,
};

export default Title;
