import React                from "react";
import PropTypes            from "prop-types";
import Styled               from "styled-components";
import Store                from "Utils/Store";
import Utils                from "Utils/Utils";

// Components
import Header               from "Components/Core/Header";
import Footer               from "Components/Core/Footer";
import Messages             from "Components/Chat/Messages";
import Send                 from "Components/Chat/Send";
import Reaction             from "Components/Chat/Reaction";
import Icon                 from "Components/Utils/Icon";




// Constants
const REACTIONS_WIDTH = 240 / 2;


// Styles
const HeaderIcon = Styled(Icon)`
    position: absolute;
    top: calc(50% - 30px);
    left: 8px;
    transform: translateY(-50%);
    cursor: pointer;

    @media (max-width: 600px) {
        top: 50%;
    }
`;

const Content = Styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 2;
    width: 100%;
    max-width: var(--main-width);
    margin: -60px auto 8px;
    background-color: white;
    border-radius: var(--border-radius);
    box-shadow: var(--box-shadow);
    overflow: hidden;
    z-index: 2;

    @media (max-width: 600px) {
        box-shadow: none;
        margin: 0;
        border-radius: 0;
    }
`;



/**
 * The Chat
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function Chat(props) {
    const { isOpen } = props;

    const { options } = Store.useState();
    const gotoStart   = Store.useStart();
    const getText     = Store.useText();

    // The Current State
    const [ submits,      setSubmits      ] = React.useState(0);
    const [ replyToID,    setReplyToID    ] = React.useState(0);
    const [ replyMessage, setReplyMessage ] = React.useState("");
    const [ reactToID,    setReactToID    ] = React.useState(0);
    const [ reactionTop,  setReactionTop  ] = React.useState(0);
    const [ reactionLeft, setReactionLeft ] = React.useState(0);

    // Variables
    const showStart    = Utils.isActive(options.showStart);
    const showChatLogo = !!options.logoFileUrl && Utils.isActive(options.showChatLogo);


    // Handles the Submit
    const handleSubmit = () => {
        setSubmits(submits + 1);
    };

    // Handles the Reply
    const handleReply = (messageID = 0, message = "") => {
        setReplyToID(messageID);
        setReplyMessage(message);
    };

    // Handles the Reaction
    const handleReaction = (messageID = 0, bounds = {}) => {
        let left = bounds.left;
        if (left - REACTIONS_WIDTH < 0) {
            left = REACTIONS_WIDTH;
        } else if (left + REACTIONS_WIDTH > window.innerWidth) {
            left = window.innerWidth - REACTIONS_WIDTH;
        }

        setReactToID(messageID);
        setReactionTop(bounds.top);
        setReactionLeft(left);
    };


    // Do the Render
    return <>
        <Header hasIcon={showStart}>
            {showStart && <HeaderIcon
                icon="back"
                onClick={() => gotoStart(true)}
            />}
            {showChatLogo && <img src={options.logoFileUrl} />}
            {getText("chatText")}
        </Header>

        <Content>
            <Messages
                isOpen={isOpen}
                submits={submits}
                onReply={handleReply}
                onReaction={handleReaction}
            />
            <Send
                replyToID={replyToID}
                replyMessage={replyMessage}
                onSubmit={handleSubmit}
                onReplyRemove={handleReply}
            />
        </Content>
        <Footer forChat />

        <Reaction
            reactToID={reactToID}
            top={reactionTop}
            left={reactionLeft}
            onClose={() => setReactToID(0)}
        />
    </>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
Chat.propTypes = {
    isOpen : PropTypes.bool.isRequired,
};

export default Chat;
