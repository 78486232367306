import React                from "react";
import PropTypes            from "prop-types";
import Styled               from "styled-components";
import Store                from "Utils/Store";
import Utils                from "Utils/Utils";

// Components
import Bubble               from "Components/Chat/Bubble";



// Styles
const Container = Styled.main`
    flex-grow: 2;
    display: flex;
    flex-direction: column;
    gap: 16px;
    min-height: 260px;
    padding-bottom: 16px;
    overflow: auto;
`;

const Day = Styled.div`
    position: sticky;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 6px 0;
    background-color: white;
    z-index: 2;
`;

const DayTitle = Styled.h3`
    margin: 0;
    padding: 4px 16px;
    text-align: center;
    font-size: 10px;
    color: var(--widget-header-text);
    background-color: var(--widget-header-bg);
    border-radius: var(--border-radius-small);
`;

const Unread = Styled.header`
    text-transform: uppercase;
    text-align: center;
    padding: 6px 0;
    background-color: var(--widget-header-bg);
`;
const UnreadTitle = Styled.h3`
    margin: 0;
    font-size: 12px;
    color: var(--widget-header-text);
`;

const Content = Styled.section.attrs(({ isMine }) => ({ isMine }))`
    display: flex;
    flex-direction: column;
    align-items: ${(props) => props.isMine ? "flex-end" : "flex-start"};
    margin: 0 8px;
`;

const User = Styled.h3`
    margin: 0;
    padding: 2px 24px;
    font-size: 14px;
`;



/**
 * The Messages
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function Messages(props) {
    const { isOpen, submits, onReply, onReaction } = props;

    const {
        showStartPage, showUnread, items, options,
        hasUnread, hasMore, lastUpdate,
    } = Store.useState();

    const getText        = Store.useText();
    const markAsRead     = Store.useMarkAsRead();
    const hideUnread     = Store.useHideUnread();
    const getOldMessages = Store.useOldMessages();


    // The References
    const containerRef = React.useRef(null);
    const unreadRef    = React.useRef(null);

    // The Current State
    const [ timeout,  setTimeout  ] = React.useState(null);
    const [ atBottom, setAtBottom ] = React.useState(true);

    // Variables
    const showStart     = Utils.isActive(options.showStart) && showStartPage;
    const showNames     = Boolean(options.showNames && options.showNames !== "None");
    const showFullNames = Boolean(options.showNames === "FullName");
    const botName       = getText("botName", "Bot");
    const unreadText    = getText("unreadText");
    const viewUnread    = Boolean(showUnread && unreadText);


    // A message was submitted
    React.useEffect(() => {
        setAtBottom(true);
    }, [ submits ]);

    // Scroll container
    React.useEffect(() => {
        if (timeout) {
            window.clearTimeout(timeout);
        }
        if (isOpen && !showStart && atBottom) {
            window.setTimeout(() => scrollToUnread(), 100);
        }
        if (isOpen && !showStart && !unreadRef.current) {
            hideUnread();
        }
    }, [ showStart, isOpen, lastUpdate ]);

    // Mark as Read
    React.useEffect(() => {
        if (isOpen && !showStart && hasUnread) {
            if (unreadRef.current) {
                setTimeout(window.setTimeout(() => {
                    markAsRead();
                    hideUnread();
                }, 2000));
            } else {
                markAsRead();
            }
        }
    }, [ isOpen, hasUnread, lastUpdate ]);


    // Handles the Scroll
    const handleScroll = async (e) => {
        let atBottom = false;
        if (e.target.scrollTop === 0 && hasMore) {
            const { oldMessageID, newMessageID } = await getOldMessages();

            // Restore the scroll at the last top
            if (containerRef.current && newMessageID) {
                window.setTimeout(() => {
                    /** @type {HTMLElement} */
                    const oldNode = document.querySelector(`#conversation-${oldMessageID}`);
                    /** @type {HTMLElement} */
                    const newNode = document.querySelector(`#conversation-${newMessageID}`);
                    if (oldNode && newNode) {
                        containerRef.current.scrollTop = oldNode.offsetTop - newNode.offsetTop;
                    }
                }, 10);
            }
        } else if (e.target.scrollTop >= (e.target.scrollHeight - e.target.offsetHeight) - 50) {
            atBottom = true;
        }
        setAtBottom(atBottom);
    };

    // Scrolls to the Unread Messages
    const scrollToUnread = () => {
        const node = unreadRef.current;
        if (node) {
            const parent = node.parentNode;
            parent.scrollTo({
                top      : node.offsetTop - parent.offsetHeight / 2,
                behavior : "smooth",
            });
        } else {
            window.setTimeout(() => scrollToBottom(), 500);
        }
    };

    // Scrolls to the Bottom of the Chat
    const scrollToBottom = () => {
        const node = containerRef.current;
        if (node) {
            node.scrollTo({
                top      : node.scrollHeight - node.offsetHeight,
                behavior : "smooth",
            });
        }
    };

    // Returns the User Name
    const getName = (elem) => {
        if (elem.userName === "Bot") {
            return botName;
        }
        if (showFullNames) {
            return elem.userName;
        }
        return elem.userFirstName;
    };



    // Do the Render
    return <Container
        ref={containerRef}
        onScroll={handleScroll}
    >
        {items.map((elem, key) => <React.Fragment key={key}>
            {!!elem.dayName && <Day>
                <DayTitle>{elem.dayName}</DayTitle>
            </Day>}

            {viewUnread && !!elem.unread && <Unread ref={unreadRef}>
                <UnreadTitle>{unreadText}</UnreadTitle>
            </Unread>}

            {!!elem.list.length && <Content isMine={elem.isMine}>
                {showNames && <User>{getName(elem)}</User>}
                {elem.list.map((item) => <Bubble
                    key={item.id}
                    id={`conversation-${item.id}`}
                    item={item}
                    isMine={elem.isMine}
                    onReply={onReply}
                    onReaction={onReaction}
                />)}
            </Content>}
        </React.Fragment>)}
    </Container>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
Messages.propTypes = {
    isOpen     : PropTypes.bool.isRequired,
    submits    : PropTypes.number.isRequired,
    onReply    : PropTypes.func,
    onReaction : PropTypes.func,
};

export default Messages;
