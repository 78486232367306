import React                from "react";
import Styled               from "styled-components";
import Store                from "Utils/Store";
import Utils                from "Utils/Utils";

// Components
import Title                from "Components/Utils/Title";
import Html                 from "Components/Utils/Html";



// Styles
const Message = Styled.div`
    padding: 12px 16px;
    border: var(--border);
    border-radius: var(--border-radius-small);
    transition: all 0.2s;
    cursor: pointer;

    &:hover {
        border-color: black;
    }
`;

const Date = Styled.p`
    margin: 0;
    font-weight: bold;
    font-size: 12px;
`;

const Text = Styled.p`
    margin: 8px 0 0 0;
`;



/**
 * The Return
 * @returns {React.ReactElement}
 */
function Return() {
    const { options, platform, lastMessage } = Store.useState();

    const getText   = Store.useText();
    const gotoStart = Store.useStart();


    // Variables
    const hasChat          = Utils.isActive(options.showChat);
    const showConversation = Boolean(platform === null && hasChat && lastMessage.id);
    const showNames        = Boolean(options.showNames && options.showNames !== "None");
    const showFullNames    = Boolean(options.showNames === "FullName");
    const botName          = getText("botName", "Bot");
    const lastMessageName  = lastMessage.userName === "Bot" ? botName : (showFullNames ? lastMessage.userName : lastMessage.userFirstName);


    // Handles the Conversation click
    const handleConversation = () => {
        gotoStart(false);
    };


    // Do the Render
    if (!showConversation) {
        return <React.Fragment />;
    }
    return <>
        <Title>{getText("restartText")}</Title>
        <Message onClick={handleConversation}>
            <Date>{Utils.getDayString(lastMessage.createdTime, true)}</Date>
            <Text>
                {showNames && <b>{lastMessageName}:&nbsp;</b>}
                <Html variant="span" formatText>
                    {Utils.makeShort(lastMessage.lastMessageText)}
                </Html>
            </Text>
        </Message>
    </>;
}

export default Return;
