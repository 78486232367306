import React                from "react";
import PropTypes            from "prop-types";
import Styled               from "styled-components";
import Store                from "Utils/Store";

// Components
import Icon                 from "Components/Utils/Icon";



// Styles
const Backdrop = Styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
`;

const Container = Styled.div.attrs(({ top, left }) => ({ top, left }))`
    position: absolute;
    top: ${(props) => `${props.top}px`};
    left: ${(props) => `${props.left}px`};
    display: flex;
    gap: 8px;
    padding: 8px 24px;
    font-size: 24px;
    background-color: rgb(244, 245, 247);
    border: 1px solid rgb(235, 236, 240);
    border-radius: 99999px;
    transform: translate(-50%, -50%);
`;

const Link = Styled.a`
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s ease-in-out;
    cursor: pointer;

    &:hover {
        opacity: 0.5;
    }
`;



/**
 * The Reaction
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function Reaction(props) {
    const { reactToID, top, left, onClose } = props;

    const sendReaction = Store.useReaction();
    const reactions    = [ "👍", "❤️", "😂", "😮", "🙏" ];


    // Handles the Emoji
    const handleEmoji = (e, emoji = "") => {
        e.preventDefault();
        sendReaction(reactToID, emoji);
        onClose();
    };


    // Do the Render
    if (!reactToID) {
        return <React.Fragment />;
    }
    return <Backdrop onClick={onClose}>
        <Container top={top} left={left}>
            {reactions.map((emoji) => <Link
                key={emoji}
                onClick={(e) => handleEmoji(e, emoji)}
            >
                {emoji}
            </Link>)}
            <Link onClick={handleEmoji}>
                <Icon icon="delete" />
            </Link>
        </Container>
    </Backdrop>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
Reaction.propTypes = {
    reactToID : PropTypes.number.isRequired,
    top       : PropTypes.number.isRequired,
    left      : PropTypes.number.isRequired,
    onClose   : PropTypes.func.isRequired,
};

export default Reaction;
