import React                from "react";
import Styled               from "styled-components";
import Store                from "Utils/Store";
import Utils                from "Utils/Utils";

// Components
import Button               from "Components/Utils/Button";
import Textarea             from "Components/Utils/Textarea";
import Title                from "Components/Utils/Title";



// Styles
const Inputs = Styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`;

const Field = Styled.div.attrs(({ hasError, multiRows }) => ({ hasError, multiRows }))`
    box-sizing: border-box;
    display: flex;
    align-items: ${(props) => props.multiRows ? "flex-end" : "center"};
    justify-content: space-between;
    gap: 8px;
    padding: 8px 8px 8px 12px;
    border: var(--border);
    background-color: #fff;
    color: #687992;
    font-size: 14px;
    line-height: 18px;
    white-space: pre-wrap;
    border-radius: var(--border-radius-small);

    ${(props) => props.hasError ? "border-color: red;" : `
        &:hover {
            border-color: black;
        }
    `}
`;

const Input = Styled.input`
    appearance: none;
    box-sizing: border-box;
    display: block;
    width: 100%;
    padding: 2px 0;
    border: none;
    font-size: 14px;

    &:focus {
        outline: none;
    }
`;

const StartButton = Styled.button`
    background-color: var(--widget-button-bg);
    color: var(--widget-button-icon);
    text-align: center;
    font-size: 14px;
    padding: 10px 8px;
    border-radius: var(--border-radius-small);
    border: 1px solid var(--widget-button-bg);
    transition: all 0.2s;
    cursor: pointer;

    &:hover {
        background-color: white;
        color: black;
    }
`;



/**
 * The Initiate
 * @returns {React.ReactElement}
 */
function Initiate() {
    const { name : urlName, options, platform, contactID, lastMessage } = Store.useState();

    const getText     = Store.useText();
    const gotoStart   = Store.useStart();
    const sendMessage = Store.useMessage();


    // The Current State
    const [ name,      setName      ] = React.useState(urlName || "");
    const [ message,   setMessage   ] = React.useState("");
    const [ rows,      setRows      ] = React.useState(1);
    const [ nameError, setNameError ] = React.useState(false);
    const [ msgError,  setMsgError  ] = React.useState(false);

    // Variables
    const hasChat           = Utils.isActive(options.showChat);
    const showPlatform      = platform !== null;
    const showChat          = Boolean(!showPlatform && hasChat && !lastMessage.id);

    const showReqName       = !contactID && !urlName && Utils.isActive(options.requestName);
    const showReqMessage    = Utils.isActive(options.requestMessage);
    const showBigButton     = Utils.isActive(options.bigStartButton);
    const showNameButton    = !showBigButton && showReqName && !showReqMessage;
    const showMessageButton = !showBigButton && showReqMessage;
    const showStartButton   = showBigButton || (!showReqName && !showReqMessage);


    // Handles the Name
    const handleName = (e) => {
        setName(e.target.value);
        setNameError(false);
    };

    // Handles the Message
    const handleMessage = (message, rows) => {
        setMessage(message);
        setRows(rows);
        setMsgError(false);
    };

    // Handles the Key Down
    const handleKeyDown = (e) => {
        if (e.keyCode === 13 && !e.shiftKey) {
            handleSubmit();
            e.preventDefault();
        }
    };

    // Handles the Submit
    const handleSubmit = () => {
        let hasError = false;
        if (showReqName && !name) {
            hasError = true;
            setNameError(true);
        }
        if (showReqMessage && !message) {
            hasError = true;
            setMsgError(true);
        }

        if (!hasError) {
            const text = !showReqMessage ? "👋" : message;
            sendMessage(name, text);
            gotoStart(false);
        }
    };


    // Do the Render
    if (!showChat) {
        return <React.Fragment />;
    }
    return <>
        <Title>{getText("conversationText")}</Title>
        <Inputs>
            {showReqName && <Field hasError={nameError}>
                <Input
                    type="text"
                    value={name}
                    placeholder={getText("requestNameText")}
                    onKeyDown={handleKeyDown}
                    onChange={handleName}
                />
                {showNameButton && <Button
                    icon="send"
                    onClick={handleSubmit}
                    isPrimary
                />}
            </Field>}
            {showReqMessage && <Field hasError={msgError} multiRows={rows > 1}>
                <Textarea
                    placeholder={getText("requestMessageText")}
                    value={message}
                    onChange={handleMessage}
                    onSubmit={handleSubmit}
                />
                {showMessageButton && <Button
                    icon="send"
                    onClick={handleSubmit}
                    isPrimary
                />}
            </Field>}
            {showStartButton && <StartButton onClick={handleSubmit}>
                {getText("startButtonText")}
            </StartButton>}
        </Inputs>
    </>;
}

export default Initiate;
